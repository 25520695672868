import { atomWithStorage } from "jotai/utils";
import { atom } from "jotai";
import moment from "moment";

import {
  AdminDetails,
  Toast,
  AvatarLink,
  QuestionStatuses,
  SupportMessageRoles,
  PaymentStatus,
  ChatDetails,
} from "./Interfaces";

export const isAuthenticatedAtom = atomWithStorage("isAuthenticated", false);
export const showSidebarAtom = atomWithStorage("showSidebar", false);
export const reduceSidebarAtom = atomWithStorage("reduceSidebar", false);

export const toastDetailsAtom = atom<Toast>({
  open: false,
  message: "A sample message",
  severity: "info",
  color: "info",
});

export const userDetails = atomWithStorage<AdminDetails | null>(
  "userDetails",
  null
);

export const createWriterAvatar = atomWithStorage<AvatarLink | null>(
  "createWriterAvatar",
  null
);

export const showDeleteWriterDialog = atom<boolean>(false);
export const showSuspendWriterDialog = atom<boolean>(false);
export const showReinstateWriterDialog = atom<boolean>(false);
export const showAlertWriterDialog = atom<boolean>(false);

export const activeQuestionsFilters = atomWithStorage<QuestionStatuses[]>(
  "activeQuestionsFilters",
  []
);

export const allQuestionsFilters = atomWithStorage<QuestionStatuses[]>(
  "allQuestionsFilters",
  [
    QuestionStatuses.published_not_assigned,
    QuestionStatuses.published_cancelled,
    QuestionStatuses.published_assigned_not_started,
    QuestionStatuses.published_assigned_in_progress,
    QuestionStatuses.published_assigned_in_review,
    QuestionStatuses.published_assigned_approved,
    QuestionStatuses.published_assigned_rejected,
    QuestionStatuses.published_completed,
    QuestionStatuses.published_completed_rated,
  ]
);

export const activeSupportChatUser = atomWithStorage<{
  id: string;
  username: string;
  avatar: string;
  role: SupportMessageRoles;
} | null>("activeSupportChatUser", null);

export const activeRequestsFilter = atomWithStorage<PaymentStatus>(
  "activeRequestsFilter",
  PaymentStatus.pending
);

export const activeChatDetails = atomWithStorage<ChatDetails | null>(
  "activeChatDetails",
  null
);

export const chatFilterAtom = atomWithStorage("chatFilterAtom", {
  activeChats: true,
});

export const expensesFilterStartDate = atomWithStorage<string>(
  "expensesFilterStartDate",
  moment().clone().startOf("month").toISOString()
);

export const expensesFilterEndDate = atomWithStorage<string>(
  "expensesFilterEndDate",
  moment().clone().endOf("month").toISOString()
);

export const showExpensesChartView = atomWithStorage<boolean>(
  "showExpensesChartView",
  false
);

export const showExpensesTableView = atomWithStorage<boolean>(
  "showExpensesTableView",
  true
);

export const scholarPaymentsFilterStartDate = atomWithStorage<string>(
  "scholarPaymentsFilterStartDate",
  moment().clone().startOf("month").toISOString()
);

export const scholarPaymentsFilterEndDate = atomWithStorage<string>(
  "scholarPaymentsFilterEndDate",
  moment().clone().endOf("month").toISOString()
);

export const showScholarPaymentsChartView = atomWithStorage<boolean>(
  "showScholarPaymentsChartView",
  false
);

export const showScholarPaymentsTableView = atomWithStorage<boolean>(
  "showScholarPaymentsTableView",
  true
);

export const activePaymentsTab = atomWithStorage<number>(
  "activePaymentsTab",
  0
);

export const otherPaymentsFilterStartDate = atomWithStorage<string>(
  "otherPaymentsFilterStartDate",
  moment().clone().startOf("month").toISOString()
);

export const otherPaymentsFilterEndDate = atomWithStorage<string>(
  "otherPaymentsFilterEndDate",
  moment().clone().endOf("month").toISOString()
);

export const showOtherPaymentsChartView = atomWithStorage<boolean>(
  "showOtherPaymentsChartView",
  false
);

export const showOtherPaymentsTableView = atomWithStorage<boolean>(
  "showScholarPaymentsTableView",
  true
);
