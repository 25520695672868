import React, { Suspense } from "react";
import { useAtom } from "jotai";
import { Routes, Route } from "react-router-dom";
import {
  isAuthenticatedAtom,
  showSidebarAtom,
  reduceSidebarAtom,
} from "src/Lib/State";
import "./Routes.css";

import Container from "@mui/material/Container";

const Nav = React.lazy(() => import("../Components/Nav"));
const Sidebar = React.lazy(() => import("../Components/Sidebar"));
const Toast = React.lazy(() => import("../Components/Toast"));

const Signin = React.lazy(() => import("../Pages/Signin"));
const Home = React.lazy(() => import("../Pages/Home"));
const Loading = React.lazy(() => import("../Pages/Loading"));
const Writers = React.lazy(() => import("../Pages/Writers"));
const WriterProfile = React.lazy(() => import("../Pages/WriterProfile"));
const AddWriter = React.lazy(() => import("../Pages/AddWriter"));
const UpdateWriterProfile = React.lazy(
  () => import("../Pages/UpdateWriterProfile")
);
const Questions = React.lazy(() => import("../Pages/Questions"));
const QuestionDetails = React.lazy(() => import("../Pages/QuestionDetails"));
const AnswersToReview = React.lazy(() => import("../Pages/AnswersToReview"));
const PaymentRequests = React.lazy(() => import("../Pages/PaymentRequests"));
const Support = React.lazy(() => import("../Pages/Support"));
const Chats = React.lazy(() => import("../Pages/Chat"));
const Expenses = React.lazy(() => import("../Pages/Expenses"));
const Scholars = React.lazy(() => import("../Pages/Scholars"));
const Payments = React.lazy(() => import("../Pages/Payments"));

function AppRoutes() {
  const [isAuthenticated] = useAtom(isAuthenticatedAtom);
  const [showSidebar, setShowSidebar] = useAtom(showSidebarAtom);
  const [reduceSidebar, setReduceSidebar] = useAtom(reduceSidebarAtom);

  return (
    <Suspense>
      <Suspense fallback={<Loading />}>
        {isAuthenticated ? (
          <div className={`grid-container`}>
            <nav className="gridHeader">
              <Nav
                showSidebar={showSidebar}
                setShowSidebar={setShowSidebar}
                reduceSidebar={reduceSidebar}
                setReduceSidebar={setReduceSidebar}
              />
            </nav>
            <aside
              className={`${showSidebar ? "active aside" : "aside"} ${
                reduceSidebar ? "reduceWidth" : ""
              } `}
            >
              <Sidebar
                setShowSidebar={setShowSidebar}
                reduceSidebar={reduceSidebar}
                setReduceSidebar={setReduceSidebar}
                showSidebar={showSidebar}
              />
            </aside>
            <main className="main">
              <Container
                maxWidth="xl"
                className="mainContentContainer"
                style={{ flex: 1 }}
              >
                <Routes>
                  <Route path="/" element={<Home />} />

                  <Route path="/writers" element={<Writers />} />
                  <Route
                    path="/writers/writer-profile/:id"
                    element={<WriterProfile />}
                  />
                  <Route path="/writers/add-writer" element={<AddWriter />} />
                  <Route
                    path="/writers/update-writer-profile/:id"
                    element={<UpdateWriterProfile />}
                  />
                  <Route path="/scholars" element={<Scholars />} />

                  <Route path="/questions" element={<Questions />} />
                  <Route
                    path="/questions/question-details/:id"
                    element={<QuestionDetails />}
                  />
                  <Route
                    path="/answers-to-review"
                    element={<AnswersToReview />}
                  />

                  <Route path="/payments" element={<Payments />} />
                  <Route path="/expenses" element={<Expenses />} />
                  <Route
                    path="/payment-requests"
                    element={<PaymentRequests />}
                  />

                  <Route path="/chats" element={<Chats />} />
                  <Route path="/support" element={<Support />} />
                </Routes>
              </Container>
            </main>
          </div>
        ) : (
          <Routes>
            <Route path="/signin" element={<Signin />} />
            <Route path="*" element={<Signin />} />
          </Routes>
        )}

        <Toast />
      </Suspense>
    </Suspense>
  );
}

export default AppRoutes;
