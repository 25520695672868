import { AlertColor } from "@mui/material";
import firebase from "firebase/compat/app";
import { Payer, PurchaseUnit } from "@paypal/paypal-js";

export interface Toast {
  color: AlertColor;
  message: string;
  open: boolean;
  severity: AlertColor;
}

export interface AdminDetails {
  id: string;
  username: string;
  avatar: string;
}

export interface Scholar {
  username: string;
  avatar: string;
  email: string;
  email_verified: boolean;
  date_joined: firebase.firestore.Timestamp;
  display_name: string;
}

export interface ScholarWithId extends Scholar {
  id: string;
}

export interface Writer extends Scholar {
  uid: string;
  bio: string;
  location: {
    country: string;
    state: string;
  };
  education: {
    school: string;
    major: string;
    date_joined: firebase.firestore.Timestamp;
    date_completed: firebase.firestore.Timestamp;
  };
  skills: WriterSkill[];
  questions_answered: number;
  questions_rated: number;
  overall_rating: number;
  overall_average_rating: number;
  payment_channels: null | PaymentChannel[];
  financials: {
    total_earned: number;
    total_paid: number;
    total_pending: number;
    active_balance: number;
    currency: string;
    available_balance: number;
    total_draft: number;
  };
  phone_number: string;
  suspension: {
    active: boolean;
    active_suspension_start_date: firebase.firestore.Timestamp | null;
    active_suspension_end_date: firebase.firestore.Timestamp | null;
  };
  infractions: WriterInfractions;
}

export interface WriterInfractions {
  overdues: number;
  suspensions: number;
  warnings: number;
}

export interface WriterSkill {
  uid: string;
  title: string;
  total_rating: number;
  average_rating: number;
  total_questions_done: number;
  total_questions_rated: number;
}

export interface PaymentChannel {
  channel: PaymentMethods;
  account: string;
}

export interface Question {
  answer_file_format: AnswerFileFormat;
  answer_length: number | null;
  assigned_to: AssignedTo | null;
  attachments: Attachment[];
  posted_on: firebase.firestore.Timestamp;
  details: string;
  duration_to_answer_number: number;
  duration_to_answer_string: DurationToAnswerString;
  posted_by: PostedBy;
  pricing: number;
  status: QuestionStatuses;
  recommended_writer: null;
  sub_topic: Topic;
  topic: Topic;
  summary: string;
  title: string;
  updated_at: firebase.firestore.Timestamp;
  question_type: QuestionType;
  uid: string;
  bids: number;
  id: string;
  answer_uid: null | Attachment[];
  amount_paid: number;
  due_date: firebase.firestore.Timestamp | null;
  standard_writer_fee: number;
  agreed_upon_writer_fee: number;
}

export interface AnswerFileFormat {
  id: number;
  uuid: string;
  format: string;
}

export interface AssignedTo {
  uuid: string;
  username: string;
  school: string;
  quote: number;
  avatar: string;
}

export interface Attachment {
  link: string;
  type: string;
  size: number;
  display_name: string;
  assigned_name: string;
}

export interface DurationToAnswerString {
  id: number;
  uuid: string;
  duration: string;
}

export interface Topic {
  id: number;
  uuid: string;
  title: string;
}

export interface PostedBy {
  uuid: string;
  name: string;
  role: string;
  avatar: string;
  username?: string;
}

export enum QuestionStatuses {
  published_not_assigned = "published_not_assigned",
  published_cancelled = "published_cancelled",
  published_assigned_not_started = "published_assigned_not_started",
  published_assigned_in_progress = "published_assigned_in_progress",
  published_assigned_in_review = "published_assigned_in_review",
  published_assigned_approved = "published_assigned_approved",
  published_assigned_rejected = "published_assigned_rejected",
  published_completed = "published_completed",
  published_completed_rated = "published_completed_rated",
}

export enum QuestionStatusesTags {
  published_not_assigned = "Unassigned",
  published_cancelled = "Cancelled",
  published_assigned_not_started = "Pending",
  published_assigned_in_progress = "In progress",
  published_assigned_in_review = "In review",
  published_assigned_approved = "Answer(s) approved",
  published_assigned_rejected = "Answer(s) rejected",
  published_completed = "Completed",
  published_completed_rated = "Completed & rated",
}

export enum QuestionType {
  academic = "academic",
  technical = "technical",
}

export interface Message {
  sent_by: string;
  sent_on: firebase.firestore.Timestamp;
  text: string;
}

export interface MessageWithAvatar {
  text: string;
  sent_on: string;
  sent_by: string;
  avatar: string;
}

export interface Review {
  uuid: string;
  rating: number;
  comment: string;
  date_published: firebase.firestore.Timestamp;
  title: string;
}

export interface AvatarLink {
  link: string;
  created_at: firebase.firestore.Timestamp;
}

export interface Bid {
  id?: string;
  uid: string;
  user: {
    username: string;
    uid: string;
    avatar: string;
    school: string;
    major: string;
  };
  request: {
    duration_number: number;
    duration_string: string;
    quote: number;
  };
  created_at: firebase.firestore.Timestamp;
  updated_at: firebase.firestore.Timestamp;
  question_uid: string;
}

export interface PaymentRequest {
  id: string;
  amount_requested: number;
  payment_method: PaymentMethods;
  payment_status: PaymentStatus;
  date_requested: firebase.firestore.Timestamp;
  currency: string;
  user: {
    id: string;
    avatar: string;
    username: string;
    display_name: string;
  };
}

export enum PaymentMethods {
  paypal = "Paypal",
  mpesa = "M-Pesa",
}

export enum PaymentStatus {
  pending = "Pending",
  rejected = "Rejected",
  processed = "Processed",
}

export interface ChatPreview {
  is_active_chat: boolean;
  last_message: string;
  last_message_scholar_has_read: boolean;
  last_message_tutor_has_read: boolean;
  last_message_sent_at: firebase.firestore.Timestamp;
  scholar_avatar: string;
  scholar_username: string;
  tutor_avatar: string;
  tutor_username: string;
  tutor_id: string;
  question: Question;
}

export interface AnswersToReview {
  answers: Attachment[];
  feedback: string;
  question: Question;
  last_upload_date: firebase.firestore.Timestamp;
  status: AnswersToReviewStatuses;
}

export interface AnswersToReviewWithId extends AnswersToReview {
  id: string;
}

export interface WriterAlertDoc {
  has_unread_notifications: boolean;
}

export interface WriterAlert {
  title: string;
  message: string;
  link: string | null;
  type: WriterAlertTypes;
  sent_on: firebase.firestore.Timestamp;
}

export enum WriterAlertTypes {
  warning = "warning",
  invite = "invite",
  notification = "notification",
  feedback = "feedback",
  suspension = "suspension",
  payment = "payment",
}

export enum AnswersToReviewStatuses {
  in_review = "In review",
  approved = "Approved",
  rejected = "Rejected",
}

export interface SupportMessagesDoc {
  user: {
    id: string;
    role: SupportMessageRoles;
    avatar: string;
    username: string;
  };
  last_message: string;
  last_message_sent_at: firebase.firestore.Timestamp;
  last_message_admin_has_read: boolean;
  last_message_user_has_read: boolean;
}

export enum SupportMessageRoles {
  scholar = "Scholar",
  tutor = "Writer",
  admin = "Administrator",
}

export interface Expense {
  id: string;
  transaction_id: string;
  request_id: string | null;
  amount: number;
  currency: string;
  recipient: {
    id: string | null;
    name: string;
    avatar: string | null;
    username: string | null;
  };
  date: firebase.firestore.Timestamp;
  transaction_cost: number;
}

export interface ChatDetails {
  questionId: string;
  dueDate: firebase.firestore.Timestamp | null;
  writerUsername: string;
  writerAvatar: string;
  writerId: string;
  scholarUsername: string;
  scholarAvatar: string;
  isActive: boolean;
}

export interface FinancialSummary {
  cash_paid_out: number;
  cash_received: number;
  transaction_costs: number;
}

export interface PayPalPayment {
  id: string;
  question_uid: string;
  amount_paid: number;
  currency: string;
  date_paid: firebase.firestore.Timestamp;
  updated_at: firebase.firestore.Timestamp;
  order_id: string;
  user_uid: string;
  status: PaypalPaymentStatuses;
  payer: Partial<Payer>;
  purchased_units: PurchaseUnit[];
}

export enum PaypalPaymentStatuses {
  COMPLETED = "COMPLETED",
  SAVED = "SAVED",
  APPROVED = "APPROVED",
  VOIDED = "VOIDED",
  PAYER_ACTION_REQUIRED = "PAYER_ACTION_REQUIRED",
}

export interface OtherPayment {
  id: string;
  scholar_id: string;
  amount_paid: number;
  email: string | null;
  name: string;
  date_paid: firebase.firestore.Timestamp;
  status: OtherPaymentStatuses;
  question_uid: string | null;
}

export enum OtherPaymentStatuses {
  COMPLETED = "COMPLETED",
  PENDING = "PENDING",
  CANCELLED = "CANCELLED",
}
